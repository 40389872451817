<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <section class="section__structure">
            <h1>Структура Администрации города Махачкалы</h1>
            <form @submit.prevent="submitFilterForm" class="filter__form" autocomplete="off">
              <div class="search">
                <div class="search__input">
                  <label for="news_search" class="search__label">
                    Имя, должность или название управления для поиска
                  </label>
                  <div class="search__input__wrap">
                    <input v-model="form.title.value" id="news_search" type="text" maxlength="255" />
                    <button v-if="form.title.value" type="button" @click="resetFilterForm">
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div class="search__btn">
                  <button type="submit" class="btn-blue_dark" :disabled="loading">
                    <LoadingIndicator v-if="loading" title="Загрузка" />
                    <template v-else>Найти</template>
                  </button>
                </div>
              </div>
            </form>
          </section>
          <section v-if="$store.state.filterData && form.title.value" class="structure-search__results">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else-if="ministers && ministers.length">
              <p>
                По запросу "{{ $store.state.filterData.title }}" найдено результатов: {{ ministers.length }}
              </p>
              <div class="structure__mayor-ministers">
                <div
                  v-for="(item, index) in ministers"
                  :key="index"
                  class="structure__mayor-minister structure-search__minister"
                >
                  <router-link
                    :to="{
                      name: 'localMinister',
                      params: { id: item.id },
                    }"
                    class="mayor-minister__name"
                  >
                    {{ item.title }}
                  </router-link>
                  <div v-if="item.department && item.department.title" class="mayor-minister__description">
                    <router-link
                      :to="{
                        name: 'localOrganization',
                        params: { id: item.department.id },
                      }"
                      v-if="isInstitution(item.department)"
                    >
                      {{ item.department.title }}
                    </router-link>
                    <a v-else :href="(item.department.url + '/structure') | url" target="_blank">
                      {{ item.department.title }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <p v-else class="_item__text">
              По запросу "{{ $store.state.filterData.title }}" ничего не найдено
            </p>
          </section>
          <template v-else>
            <section
              v-if="mayor && mayor.mayor"
              class="structure__block structure__block-mayor section__structure"
            >
              <div class="structure__mayor">
                <div class="structure__mayor-img-wrap">
                  <div class="structure__mayor-img">
                    <div class="img__container">
                      <img
                        :src="mayor.mayor.head_img | image($store.state.api)"
                        :alt="mayor.mayor.head_img | image_alt"
                      />
                    </div>
                  </div>
                  <div class="structure__mayor-name__wrap">
                    <div class="structure__mayor-name">
                      {{ mayor.mayor.surname + " " + mayor.mayor.name + " " + mayor.mayor.patronymic }}
                    </div>
                    <div class="structure__mayor-description">
                      {{ mayor.mayor.description }}
                    </div>
                  </div>
                </div>

                <div class="structure__mayor-info">
                  <div class="structure__mayor-ministers">
                    <div v-for="(item, index) in mayor.childs" :key="index" class="structure__mayor-minister">
                      <router-link
                        :to="{
                          name: 'localMinister',
                          params: { id: item.id },
                        }"
                        class="mayor-minister__name"
                      >
                        {{ item.title }}
                      </router-link>
                      <div
                        v-if="item.department && item.department.title"
                        class="mayor-minister__description"
                      >
                        <router-link
                          :to="{
                            name: 'localOrganization',
                            params: { id: item.department.id },
                          }"
                          v-if="isInstitution(item.department)"
                        >
                          {{ item.department.title }}
                        </router-link>
                        <a v-else :href="(item.department.url + '/structure') | url" target="_blank">
                          {{ item.department.title }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="structure__blocks">
              <section
                v-if="deputies && deputies.ministers && deputies.ministers.length"
                class="section__structure"
              >
                <h2>{{ deputies.name }}</h2>
                <div class="structure__block">
                  <div class="structure__slider">
                    <swiper ref="deputySwiper" :options="deputySwiperOptions">
                      <swiper-slide v-for="(item, index) in deputies.ministers" :key="index">
                        <div :class="{ 'structure__slide-gray': index % 2 !== 0 }" class="structure__slide">
                          <router-link
                            :to="{
                              name: 'localMinister',
                              params: { id: item.id },
                            }"
                            class="structure__minister structure__minister-main"
                          >
                            <div class="structure__minister-img">
                              <img
                                :src="item.head_img | image($store.state.api)"
                                :alt="item.head_img | image_alt"
                              />
                            </div>
                            <div class="structure__minister-name">
                              {{ item.surname }} <br />
                              {{ item.name }} <br />
                              {{ item.patronymic }}
                            </div>
                            <div
                              v-if="item.department && item.department.title"
                              class="structure__minister-description"
                            >
                              <router-link
                                :to="{
                                  name: 'localOrganization',
                                  params: { id: item.department.id },
                                }"
                                v-if="isInstitution(item.department)"
                              >
                                {{ item.department.title }}
                              </router-link>
                              <a v-else :href="(item.department.url + '/structure') | url" target="_blank">
                                {{ item.department.title }}
                              </a>
                            </div>
                          </router-link>

                          <div
                            v-if="item.deputy_childs && item.deputy_childs.length"
                            class="structure__sub-ministers"
                          >
                            <span class="structure__sub-ministers-title">Заместители</span>
                            <div
                              v-for="(child, i) in item.deputy_childs"
                              :key="i"
                              class="structure__minister structure__minister-blue"
                            >
                              <router-link
                                :to="{
                                  name: 'localMinister',
                                  params: { id: child.id },
                                }"
                                class="structure__minister-name"
                              >
                                {{ child.surname }}
                                {{ child.name }}
                                {{ child.patronymic }}
                              </router-link>
                              <div
                                v-if="child.department && child.department.title"
                                class="structure__minister-description"
                              >
                                <router-link
                                  :to="{
                                    name: 'localOrganization',
                                    params: { id: child.department.id },
                                  }"
                                  v-if="isInstitution(child.department)"
                                >
                                  {{ child.department.title }}
                                </router-link>
                                <a v-else :href="(child.department.url + '/structure') | url" target="_blank">
                                  {{ child.department.title }}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div v-if="item.childs && item.childs.length" class="structure__sub-ministers">
                            <span class="structure__sub-ministers-title">Руководители подразделений</span>
                            <div v-for="(child, i) in item.childs" :key="i" class="structure__minister">
                              <router-link
                                :to="{
                                  name: 'localMinister',
                                  params: { id: child.id },
                                }"
                                class="structure__minister-name"
                              >
                                {{ child.surname }}
                                {{ child.name }}
                                {{ child.patronymic }}
                              </router-link>
                              <div
                                v-if="child.department && child.department.title"
                                class="structure__minister-description"
                              >
                                <router-link
                                  :to="{
                                    name: 'localOrganization',
                                    params: { id: child.department.id },
                                  }"
                                  v-if="isInstitution(child.department)"
                                >
                                  {{ child.department.title }}
                                </router-link>
                                <a v-else :href="(child.department.url + '/structure') | url" target="_blank">
                                  {{ child.department.title }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div class="slider__controls-deputies">
                      <a @click.prevent="deputySwiper.slidePrev()" class="slider__controls__left">
                        <ArrowLeft />
                      </a>
                      <a @click.prevent="deputySwiper.slideNext()" class="slider__controls__right">
                        <ArrowRight />
                      </a>
                      <div class="structure__slider-pagination" slot="pagination"></div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                v-if="assistants && assistants.ministers && assistants.ministers.length"
                class="section__structure"
              >
                <h2>{{ assistants.name }}</h2>
                <div class="structure__block">
                  <div class="structure__slider">
                    <swiper ref="assistantSwiper" :options="assistantSwiperOptions">
                      <swiper-slide v-for="(item, index) in assistants.ministers" :key="index">
                        <div :class="{ 'structure__slide-gray': index % 2 !== 0 }" class="structure__slide">
                          <router-link
                            :to="{
                              name: 'localMinister',
                              params: { id: item.id },
                            }"
                            class="structure__minister structure__minister-main"
                          >
                            <div class="structure__minister-img">
                              <img
                                :src="item.head_img | image($store.state.api)"
                                :alt="item.head_img | image_alt"
                              />
                            </div>
                            <div class="structure__minister-name">
                              {{ item.surname }} <br />
                              {{ item.name }} <br />
                              {{ item.patronymic }}
                            </div>
                            <div
                              v-if="item.department && item.department.title"
                              class="structure__minister-description"
                            >
                              <router-link
                                :to="{
                                  name: 'localOrganization',
                                  params: { id: item.department.id },
                                }"
                                v-if="isInstitution(item.department)"
                              >
                                {{ item.department.title }}
                              </router-link>
                              <a v-else :href="(item.department.url + '/structure') | url" target="_blank">
                                {{ item.department.title }}
                              </a>
                            </div>
                          </router-link>
                        </div>
                      </swiper-slide>
                    </swiper>

                    <a @click.prevent="assistantSwiper.slidePrev()" class="slider__controls__left">
                      <ArrowLeft />
                    </a>
                    <a @click.prevent="assistantSwiper.slideNext()" class="slider__controls__right">
                      <ArrowRight />
                    </a>

                    <div class="assistants__slider-pagination" slot="pagination"></div>
                  </div>
                </div>
              </section>
              <section
                v-if="districtHeads && districtHeads.ministers && districtHeads.ministers.length"
                class="section__districts-ministers"
              >
                <h2>{{ districtHeads.name }}</h2>
                <div class="structure__block">
                  <div class="structure__slider">
                    <swiper ref="lastSwiper" :options="lastSwiperOptions">
                      <swiper-slide v-for="(item, index) in districtHeads.ministers" :key="index">
                        <div :class="{ 'structure__slide-gray': index % 2 !== 0 }" class="structure__slide">
                          <router-link
                            :to="{
                              name: 'localMinister',
                              params: { id: item.id },
                            }"
                            class="structure__minister structure__minister-main"
                          >
                            <div class="structure__minister-img">
                              <img
                                :src="item.head_img | image($store.state.api)"
                                :alt="item.head_img | image_alt"
                              />
                            </div>
                            <div class="structure__minister-name">
                              {{ item.surname }} <br />
                              {{ item.name }} <br />
                              {{ item.patronymic }}
                            </div>
                            <div
                              v-if="item.department && item.department.title"
                              class="structure__minister-description"
                            >
                              <router-link
                                :to="{
                                  name: 'localOrganization',
                                  params: { id: item.department.id },
                                }"
                                v-if="isInstitution(item.department)"
                              >
                                {{ item.department.title }}
                              </router-link>
                              <a v-else :href="(item.department.url + '/structure') | url" target="_blank">
                                {{ item.department.title }}
                              </a>
                            </div>
                          </router-link>
                        </div>
                      </swiper-slide>
                    </swiper>
                    <a @click.prevent="lastSwiper.slidePrev()" class="slider__controls__left">
                      <ArrowLeft />
                    </a>
                    <a @click.prevent="lastSwiper.slideNext()" class="slider__controls__right">
                      <ArrowRight />
                    </a>
                    <div class="last__slider-pagination" slot="pagination"></div>
                  </div>
                </div>
              </section>
            </div>
            <!--          <div class="structure__about-link">-->
            <!--            <a href="#">-->
            <!--              Сведения о доходах, расходах, об имуществе и обязательствах имущественного характера-->
            <!--              государственных гражданских служащих города Махачкалы и лиц, замещающих муниципальные должности-->
            <!--              города Махачкалы, и членов их семей за (период)-->
            <!--            </a>-->
            <!--          </div>-->
          </template>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";

export default {
  name: "LocalStructure",
  async asyncData({ store }) {
    await store.dispatch("GET_STRUCTURE_PAGE");
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      deputySwiperOptions: {
        slidesPerView: 1,
        loop: false,
        clickable: true,
        speed: 800,
        watchOverflow: true,
        autoHeight: true,
        breakpoints: {
          420: {
            slidesPerView: 1.5,
          },
          520: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2.5,
          },
          720: {
            slidesPerView: 3,
          },
          880: {
            slidesPerView: 3.5,
          },
          950: {
            slidesPerView: 4,
          },
          1050: {
            slidesPerView: 4.5,
          },
          1200: {
            slidesPerView: 5,
          },
        },
        pagination: {
          el: ".structure__slider-pagination",
          clickable: true,
        },
      },
      assistantSwiperOptions: {
        slidesPerView: 1.1,
        loop: false,
        clickable: true,
        speed: 800,
        watchOverflow: true,
        breakpoints: {
          420: {
            slidesPerView: 1.5,
          },
          520: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2.5,
          },
          720: {
            slidesPerView: 3,
          },
          880: {
            slidesPerView: 3.5,
          },
          950: {
            slidesPerView: 4,
          },
          1050: {
            slidesPerView: 4.5,
          },
          1200: {
            slidesPerView: 5,
          },
        },
        pagination: {
          el: ".assistants__slider-pagination",
          clickable: true,
        },
      },
      lastSwiperOptions: {
        slidesPerView: 1.1,
        loop: false,
        clickable: true,
        speed: 800,
        watchOverflow: true,
        breakpoints: {
          420: {
            slidesPerView: 1.5,
          },
          520: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2.5,
          },
          720: {
            slidesPerView: 3,
          },
          880: {
            slidesPerView: 3.5,
          },
          950: {
            slidesPerView: 4,
          },
          1050: {
            slidesPerView: 4.5,
          },
          1200: {
            slidesPerView: 5,
          },
        },
        pagination: {
          el: ".last__slider-pagination",
          clickable: true,
        },
      },
      form: {
        title: {
          value: null,
          message: null,
        },
      },
      ministers: [],
    };
  },
  watch: {
    "form.title.value"() {
      if (!this.form.title.value) {
        this.$store.state.filterData = null;
      }
    },
  },
  computed: {
    mayor() {
      if (this.$store.state.structure_page && this.$store.state.structure_page.types) {
        return this.$store.state.structure_page.types.find(
          (type) => type.code === this.$store.state.type.CODE_MAYOR
        ).ministers[0];
      }
      return {};
    },
    deputies() {
      return this.$store.state.structure_page.types
        ? this.$store.state.structure_page.types.find(
            (type) => type.code === this.$store.state.type.CODE_DEPUTY_HEADS
          )
        : {};
    },
    assistants() {
      return this.$store.state.structure_page.types
        ? this.$store.state.structure_page.types.find(
            (type) => type.code === this.$store.state.type.CODE_ASSISTANT_HEADS
          )
        : {};
    },
    districtHeads() {
      return this.$store.state.structure_page.types
        ? this.$store.state.structure_page.types.find(
            (type) => type.code === this.$store.state.type.CODE_HINTERLAND_HEADS
          )
        : {};
    },
    types() {
      if (this.$store.state.structure_page && this.$store.state.structure_page.types) {
        return this.$store.state?.structure_page?.types.slice(2);
      }
      return [];
    },
    deputySwiper() {
      return this.$refs.deputySwiper.$swiper;
    },
    assistantSwiper() {
      return this.$refs.assistantSwiper.$swiper;
    },
    lastSwiper() {
      return this.$refs.lastSwiper.$swiper;
    },
  },
  methods: {
    isInstitution(item) {
      return (item.type && item.type.code === this.$store.state.type.CODE_INSTITUTIONS) || !item.url;
    },
    /**
     * Фильтр учреждений и организаций
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        let fd = {
          title: this.form.title.value,
        };
        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("GET_STRUCTURE_MINISTERS", fd)
          .then(({ data }) => {
            this.loading = false;
            this.ministers = data.ministers;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт Главы города Махачкалы",
      "",
      "",
      "Официальный сайт Главы города Махачкалы",
      "",
      ""
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"

.section__structure {
  margin-bottom: 48px;
  +below(1200px) {
    margin-bottom: 24px;
  }
}

.section__districts-ministers {
  margin-bottom: 24px;
}

.structure {
  &-search__results {
    min-height 10vh
  }

  &__blocks {
    //+below(480px) {
    //  display none
    //}

    //&-mobile {
    //  display flex
    //  flex-direction column
    //  gap 24px
    //  +above(481px) {
    //    display none
    //  }
    //}
  }

  &__block-mobile {
    .structure__minister-wrap {
      padding 12px 8px

      &.structure__minister-gray {
         background: linear-gradient(90deg, #ECECEC 0%, rgba(245, 245, 247, 0) 100%);
       }
    }
  }

  &__block {
    padding-left 46px;
    padding-right 46px;
    +below(1200px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    &.structure__block-mayor {
      +below(1200px) {
        padding-left 24px
        padding-right: 24px;
      }
      +below(768px) {
        padding-left 0
        padding-right: 0
      }
    }
  }

  &__mayor {
    display flex
    align-items center
    +below(560px) {
      flex-direction column
    }

    &-img {
      ratio(600, 600)
      &-wrap {
        width 360px
        margin-right: 112px;
        +below(1200px) {
          margin-right: 95px;
          max-width 380px
        }
        +below(990px) {
          margin-right: 48px
          max-width 300px
        }
        +below(768px) {
          max-width 235px
        }
        +below(660px) {
          margin-right: 32px
        }
        +below(560px) {
          margin-right: 0
          margin-bottom: 24px;
        }
        +below(380px) {
          max-width 217px
          margin-bottom 16px
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-name__wrap {
      margin-top 32px
      text-align center
      +below(420px) {
        margin-top: 24px;
      }
    }

    &-name {
      font-weight: 500;
      font-size: 1.75em
      line-height: 128%
      text-align: center;
      color: var(--color_blue_dark);
      margin-bottom: 8px;
      +below(990px) {
        font-size: 1.375em
        line-height: 28px;
      }
      +below(768px) {
        font-weight 500
      }
      +below(640px) {
        font-size: 1.125em
        line-height: 24px;
      }
    }

    &-ministers {
      +below(1200px) {
        margin-top: auto;
      }

      &-title {
        font-size: 1.125em
        line-height: 130%
        color: var(--color_dark)
        margin-bottom 16px
        display flex
        +below(560px) {
          text-align center
        }
      }
    }

    &-minister {
      &:not(:last-child) {
        margin-bottom: 28px;
        +below(768px) {
          margin-bottom: 16px;
        }
      }

      .mayor-minister__name {
        display block
        font-weight: 500;
        font-size: 1.375em
        line-height: 28px;
        margin-bottom: 12px;
        color var(--color_black)
        +below(990px) {
          font-size: 1em
          line-height: 20px;
        }
        +below(768px) {
          font-size: 0.875em
          margin-bottom: 4px;
        }
      }

      .mayor-minister__description {
        +below(990px) {
          font-size: 0.875em
          line-height: 20px;
        }
        +below(768px) {
          font-size: 0.75em
          line-height 16px
        }
      }

      &.structure-search__minister {
        .mayor-minister__name {
          font-size: 1.25em
          line-height 24px
          +below(990px) {
            font-size: 1.125em
            line-height: 20px;
          }
          +below(768px) {
            font-size: 1em
            margin-bottom: 4px;
          }
        }

        .mayor-minister__description {
          +below(990px) {
            font-size: 0.875em
            line-height: 20px;
          }
        }
      }
    }
  }

  &__slider {
    position relative

    .slider__controls {
      &-deputies {
        .slider__controls__left
        .slider__controls__right {
          top 230px
        }
      }

      &__left
      &__right {
        width 40px
        height 40px
        position absolute
        top 50%
        //transform translateY(-50%)
        z-index 4
        display flex
        align-items center
        justify-content center
        border none

        svg {
          width 32px
          height 32px

          path {
            fill var(--color_gray_dark)
            transition 0.3s
          }
        }

        &:hover {
          svg path {
           fill var(--color_blue)
          }
        }
      }

      &__left {
        left 0
        transform translate3d(-100%, -50%, 0)
      }

      &__right {
        right 0
        transform translate3d(100%, -50%, 0)
      }
    }

    &-pagination,
    .assistants__slider-pagination,
    .last__slider-pagination {
      display flex
      align-items center
      justify-content center
      margin: -6px
      margin-top 24px

      .swiper-pagination-bullet {
        width 32px
        height: 2px
        margin 6px
        border-radius 0
        background: var(--color_blue_dark)
        opacity: 0.4;

        &-active {
          opacity 1
        }
      }
    }
  }

  &__slide {
    padding 16px
    border-radius: 4px;

    &-gray {
      //background linear-gradient(90deg, #ECECEC 0%, rgba(245, 245, 247, 0) 100%);
      background var(--color_gray_light)
    }
  }

  &__minister {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 16px;
      +below(480px) {
        margin-bottom: 12px;
      }
    }

    &-img {
      width 160px;
      height 160px
      min-width 120px
      margin-bottom: 8px;

      img {
        width 100%
        height 100%
        object-fit cover
        object-position center
      }
    }

    &-name {
      font-weight: 500;
      font-size: 1.125em
      line-height: 24px;
      margin-bottom: 8px;
      text-align center
      +below(480px) {
        font-size 1em
        line-height 20px
        word-break break-word
      }
      +below(420px) {
        font-size 0.875em
      }
    }

    &-description {
      font-size: 14px;
      line-height: 20px;
      color var(--color_gray_dark)
      text-align center
      textcut(3)
      +below(380px) {
        font-size 0.75em
        line-height 16px
      }
    }
  }

  &__sub-ministers {
    padding-top 16px
    margin-bottom 16px
    border-top 1px solid var(--color_gray_dark)
    +below(480px) {
      padding-top 12px
    }
    +below(320px) {
      grid-template-columns 1fr
    }

    &-title {
      font-size: 0.875em
      line-height: 20px;
      color: var(--color_dark)
      margin-bottom: 16px
      display flex
      justify-content center
      text-align center
    }
  }

  &__minister-blue,
  &__minister-main {
    .structure__minister-name {
      color var(--color_blue_dark)
    }
  }

  &__minister-main {
    align-items center
    text-align center

    .structure__minister-name {
      +below(420px) {
        font-size 1em
        line-height 20px
      }
    }
  }

  &__about-link {
    width 100%
    padding-top 24px
    border-top 1px solid var(--color_gray_divider)

    a {
      color var(--color_blue_dark)
    }
  }
}
</style>
